@use 'src/app/variables' as *;

.description {
  color: var(--io-text-dimmed-700);
  font-weight: 400;
  width: 80%;
}

.divider {
  margin: var(--io-spacing-6) 0;
  border-top: 1px solid var(--io-border-main);
}

.header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: var(--io-spacing-1) !important;
}

.modal {
  width: 660px;
}

.toastLinkContainer {
  display: flex;
  flex-direction: row;
  gap: var(--io-spacing-1);
  align-items: center;
  color: var(--io-text) !important;
}
