@use 'src/app/variables' as *;

.alert {
  border: 1px solid;
  border-radius: 4px;
  font-size: var(--io-font-size-base);
  display: flex;

  &.inline {
    background-color: transparent;
    border-color: transparent;
  }
}

.main {
  display: flex;
  padding: var(--io-spacing-4);
  flex: 1 1 0;
}
.dismissible .main {
  padding-right: 0;
}

.inline .main {
  padding: 0;
}

.iconWrapper {
  margin-right: var(--io-spacing-2);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  color: var(--alert-icon-color, currentColor);
}

.icon {
  width: toRem(20);
  height: toRem(20);
}

.content {
  flex: 1;
  text-align: start;
}
.content > *:last-child {
  margin-bottom: 0;
}

/**
 * Dismiss button
 */
.dismissWrapper {
  --dismiss-wrapper-padding: var(--io-spacing-3);
  padding-top: var(--dismiss-wrapper-padding);
  padding-right: var(--dismiss-wrapper-padding);
  padding-left: var(--dismiss-wrapper-padding);
}

/**
 * Variants
 */
.success {
  --alert-icon-color: var(--io-success);
  background-color: var(--io-success-faded);
  border-color: var(--io-success-medium);
}
.warning {
  --alert-icon-color: var(--io-warning);
  background-color: var(--io-warning-faded);
  border-color: var(--io-warning-medium);
}
.error {
  --alert-icon-color: var(--io-danger);
  background-color: var(--io-danger-faded);
  border-color: var(--io-danger-medium);
}
.info {
  --alert-icon-color: var(--io-info);
  background-color: var(--io-info-faded);
  border-color: var(--io-info-medium);
}
