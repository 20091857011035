@use 'src/app/variables' as *;

.container {
  display: inline-flex;
  padding: var(--io-spacing-0_5) var(--io-spacing-2);
  line-height: inherit;
  align-items: center;
  justify-content: center;
  gap: var(--io-spacing-1);
  height: toRem(24);
  border-radius: toRem(4);
}

.suspicious {
  background: var(--red-200);
}

.unverified {
  background: var(--grey-200);
}

.suspiciousIcon {
  width: toRem(18);
  height: toRem(18);
  color: var(--red-500);
}

.unverifiedIcon {
  width: toRem(18);
  height: toRem(18);
  color: var(--grey-500);
}

.label {
  font-weight: 600;
  font-size: var(--io-font-size-small);
}
