@use 'src/app/variables' as *;

.assetList {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: var(--io-spacing-6);
  margin-top: var(--io-spacing-6);
}

.assetListItem {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: 'icon data hide';
  column-gap: var(--io-spacing-4);
  row-gap: var(--io-spacing-0_5);
  align-items: center;
}

.nativeListItem {
  grid-template-columns: min-content 1fr;
  grid-template-areas: 'icon name hide';
}

.assetIcon {
  --size: #{toRem(36)};
  grid-area: icon;
  gap: 100px;
  align-self: flex-start;
}

.assetData {
  display: flex;
  flex-direction: column;
  gap: var(--io-spacing-0_5);
  align-items: flex-start;
}

.assetName {
  font-weight: 500;
  font-size: var(--io-font-size-large);
  display: inline-flex;
  align-items: center;
  gap: var(--io-spacing-2);
  line-height: var(--io-line-height-body);
}

.assetAddress {
  color: var(--io-text-dimmed-700);
}

.assetAddressLabel {
  display: inline-block;
  margin-right: var(--io-spacing-2);
}

.assetBalance {
  color: var(--io-text-dimmed-700);
}

.assetHide {
  grid-area: hide;
}

.staticItemTitle {
  color: var(--io-text-dimmed-700);
  margin-top: var(--io-spacing-8);
}
