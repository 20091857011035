@use 'src/app/variables' as *;

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--io-spacing-3);
}

.assetIcon {
  --size: #{toRem(36)};
}

.symbol {
  font-weight: 500;
}

.assetAddress {
  color: var(--io-text-dimmed-700);
}

.assetData {
  display: flex;
  flex-direction: column;
  gap: var(--io-spacing-0_5);
  align-items: flex-start;
  flex-grow: 1;
}

.assetName {
  display: inline-flex;
  align-items: center;
  gap: var(--io-spacing-2);
  font-weight: 400;
  line-height: var(--io-line-height-body);
}

.tooltip {
  padding-right: var(--io-spacing-3);
}

.assetNameWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--io-spacing-3);
}
