@use 'src/app/variables' as *;

.manageAssetsDropdownContainer {
  display: flex;
  flex-direction: column;
  @media (min-width: $viewport-lg) {
    flex-direction: row;
    gap: var(--io-spacing-3);
    & > *:first-child {
      flex: 3;
    }

    & > *:last-child {
      flex: 1;
    }
  }
}
