@use 'src/app/variables' as *;

.networkIcon {
  position: relative;
  width: toRem(28);
  height: toRem(28);
  margin-left: var(--io-spacing-3);
}

.singleNetworkInput {
  height: 56px;
}

.singleNetworkIcon {
  position: relative;
  width: toRem(28);
  height: toRem(28);
}

.option {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: var(--io-spacing-2);
}

.optionIcon {
  position: relative;
  width: toRem(22);
  height: toRem(22);
}
